.App {
  text-align: center;
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
}

/* https://stackoverflow.com/questions/643879/css-to-make-html-page-footer-stay-at-bottom-of-the-page-with-a-minimum-height-b */
.App-main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-label {
  color:rgba(0, 0, 0, 0.6);
}

.App-bar {
  background-color: #ffffff !important;
  align-items: center;
  padding: 15px 0;
  min-height: 50px;
}

.App-banner {
  background-color: #3f72af;
  padding: 30px 50px;
  color: #ffffff;
}

.App-footer {
  background-color: #3f72af;
  padding: 20px 0;
  color: #ffffff;
  min-height: 50px;
}

.App-footer a {
  color: #ffffff;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.6;
  text-decoration: none;
}

.App-footer a:hover {
  text-decoration: underline;
}

.grid-container { flex: 1; background-color: #f3f3f3; padding-bottom:20px;}

.grid-container a { text-decoration: none; }
.grid-container a p { color: #111; font-size: 1rem;}
.grid-container a h4 { color: #D27D2D; font-weight: bold; font-size: 1.15rem; }

.grid-container a .paper-grid-item { background-color: #fff; display:flex; flex-direction: column; }
.grid-container a .paper-grid-item-top { padding: 8px; background-color: #fff; border-radius: 4px 4px 0 0; border-bottom: 1px solid #D27D2D; }
.grid-container a .paper-grid-item-bottom { padding: 16px 16px 24px 16px; flex: 1 }
.grid-container a .paper-grid-item-button { margin:0 16px 16px 16px; }

.grid-container a:hover .paper-grid-item { box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%); }

.calculator-text-field { background-color: #fff; margin-top: 16px !important; width: 100%; max-width: 224px !important; }

@media only screen and (max-width: 899px) and (min-width: 449px)  {
  .calculator-text-field {margin-left: 8px !important; margin-right: 8px !important;}
}

@media only screen and (max-width: 599px)  {
  .share-button-grid-item {text-align: left !important; padding:0 0 24px 16px;}

  .results-grid-container {flex-basis: 100%; -webkit-box-flex: 0; flex-grow: 0; max-width: 100%;}
}